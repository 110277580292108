<template>
  <div class="border-all px-6 py-1 ma-4 rounded">
    <v-row v-if="!employee" class="row-reset">
      <v-col cols="12" v-for="i in 10" :key="i">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><v-skeleton-loader type="list-item"></v-skeleton-loader
          ></v-col>
          <v-col cols="9"
            ><v-skeleton-loader type="list-item"></v-skeleton-loader
          ></v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else class="row-reset">
      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Foto şəkil</span></v-col
          >
          <v-col cols="9">
            <v-avatar size="170" v-if="profilePhoto">
              <img :src="profilePhoto" :alt="employee.firstName" />
            </v-avatar>
            <v-avatar
              class="border-all"
              size="170"
              style="margin-right: 10px"
              v-else
            >
              <span class="body-2">{{
                employee.firstName.charAt(0).toUpperCase()
              }}</span>
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ad Soyad Ata</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.firstName +
                  " " +
                  employee.lastName +
                  " " +
                  employee.middleName
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ailə vəziyyəti</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.maritalStatus ? employee.maritalStatus : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Cinsi</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.gender ? employee.gender : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><span class="subtitle-2 gray--text">FİN</span></v-col>
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.personalIdentificationNumber
                  ? employee.personalIdentificationNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text"
              >Şəxsiyyət vəsiqənin seriya nömrəsi</span
            ></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.idCardSerialNumber ? employee.idCardSerialNumber : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Doğum tarixi</span></v-col
          >
          <v-col cols="9"
            ><p class="body-2 gray--text text--darken-3 capitalize">
              {{
                employee.birthDate ? convertBirthday(employee.birthDate) : "—"
              }}
            </p></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Vətəndaşlıq</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.citizenship ? employee.citizenship : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Milliyyəti</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.nationality ? employee.nationality : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Təhsil</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.educationDegree ? employee.educationDegree : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">E-ünvan</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.emailAddress ? employee.emailAddress : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Mobil nömrə</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.mobileNumber ? employee.mobileNumber : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Korporativ nömrə</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.corporationPhoneNumber
                  ? employee.corporationPhoneNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Daxili nömrə</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.internalPhoneNumber
                  ? employee.internalPhoneNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ev telefonu</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.homePhoneNumber ? employee.homePhoneNumber : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Yaxın qohum(mob)</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.relativePhoneNumber
                  ? employee.relativePhoneNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Şirkət</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.company ? employee.company : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Departament</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.department ? employee.department : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Vəzifə təsnifatı</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.employmentType ? employee.employmentType : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Vəzifə</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.position ? employee.position : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">İş vaxtı</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.workScheduleType ? employee.workScheduleType : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">Ştat tutumu</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.staffType ? employee.staffType : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">İş yeri</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ employee.workPlaceType ? employee.workPlaceType : "—" }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text"
              >Sosial sığorta nömrəsi</span
            ></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{
                employee.socialInsuaranceCardNumber
                  ? employee.socialInsuaranceCardNumber
                  : "—"
              }}
            </span></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from "@/services";
import Utils from "../../../Shared/mixins/utils";

export default {
  mixins: [Utils],
  data() {
    return {
      employee: null,
      profilePhoto: null,
    };
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.employee = await API.viewHREmployee(this.$route.params.id);
        this.getProfilePhoto();
      }
    },
    convertBirthday(date) {
      return (
        this.$moment(date).format("MMMM DD YYYY") +
        " (" +
        this.calculateAge(date) +
        " yaş)"
      );
    },
    getProfilePhoto() {
      let response = API.fetchHRProfilePhoto(this.employee.id, 0, 0);
      response
        .then((res) => {
          this.profilePhoto = res;
        })
        .catch(() => {
          this.profilePhoto = null;
        });
    },
  },
  created() {
    this.checkID();
  },
};
</script>
<style scoped>
.capitalize::first-letter {
  text-transform: uppercase !important;
}
</style>
